import restApiService from '@/services/restApiService.js'
import moment from 'moment'

export default class GestionPresence {
    #salon = null
    #usagerId = null
    #heureEntreeSalon = null
    #intervalAnoncerPresence = null

    constructor(usagerId, salon) {
        this.#salon = salon
        this.#usagerId = usagerId
    }

    demarrer() {
        this.anoncerPresence()
        this.#intervalAnoncerPresence = setInterval(() => {
            console.log(this.#usagerId, this.#salon, this.#heureEntreeSalon, this.#intervalAnoncerPresence)
            this.anoncerPresence()
        }, 1000 * 64 * 15)
    }

    anoncerPresence() {
        var notification = {
            salon: this.#salon,
            usager: this.#usagerId,
            zdtHeureEntree: this.#heureEntreeSalon,
        }
        console.log('envoi notification', notification)
        restApiService
            .post('/api/presences/notification', notification)
            .then(result => {
                if (!this.#heureEntreeSalon) {
                    this.#heureEntreeSalon = result.data.heureReception
                    this.anoncerPresence()
                }
            })
            .catch(error => console.log(`Erreur l'ors de l'envoi de la notification ${Date.now} : ${error}`))
    }

    arreter() {
        this.anoncerPresence()
        return clearInterval(this.#intervalAnoncerPresence)
    }

    heureActuelle() {
        var zone = moment.tz.guess()
        var localDateTime = moment().format('YYYY-MM-DDTHH:mm:ssZ')
        console.log('zone', zone)
        return localDateTime + '[' + zone + ']'
    }
}
