export default {
    getUsagerDto (instance, usager) {
        const dtoUsager = {
            instance,
            nomUsager: usager.courriel,
            prenom: usager.prenom,
            nom: usager.nom,
            courriel: usager.courriel,
            pseudo: `${usager.prenom} ${usager.nom}`,
        }

        return dtoUsager
    },
    getChaine (conference, kiosque, chaine) {
        let chaineComputed
        if (conference) {
            chaineComputed = `conference-${conference.id}`
        } else if (kiosque) {
            chaineComputed = `kiosque-${kiosque.id}`
        } else if (chaine) {
            chaineComputed = chaine
        }
        return chaineComputed
    },
}
