var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{attrs:{"color":"success","icon":"mdi-chat-processing-outline","inline":"","title":_vm.$t('prise_parole.gestion.titre')}},[_c('v-checkbox',{attrs:{"label":_vm.$t('prise_parole.gestion.afficher_historique')},model:{value:(_vm.afficherHistorique),callback:function ($$v) {_vm.afficherHistorique=$$v},expression:"afficherHistorique"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.priseParolesFiltrees,"item-key":"id","footer-props":{
      itemsPerPageText: _vm.$t('lignes_par_page'),
      itemsPerPageAllText: _vm.$t('tout'),
      pageText: ("{0}-{1} " + (_vm.$t('general.de')) + " {2}")
    }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHeure(item.dateDemande))+" ")]}},{key:"item.nom",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(((item.usager.prenom) + " " + (item.usager.nom)))+" ")]}},(_vm.afficherHistorique)?{key:"item.statut",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatut(item))+" ")]}}:null,{key:"item.accorder",fn:function(ref){
    var item = ref.item;
return [(_vm.isNouveau(item))?_c('v-btn',{staticClass:"success",attrs:{"icon":""},on:{"click":function($event){return _vm.accorder(item)}}},[_c('v-icon',[_vm._v("mdi-microphone")])],1):_vm._e()]}},{key:"item.refuser",fn:function(ref){
    var item = ref.item;
return [(_vm.isNouveau(item))?_c('v-btn',{staticClass:"red",attrs:{"icon":""},on:{"click":function($event){return _vm.refuser(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }