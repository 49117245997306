<template>
  <div>
    <v-card-title>
      <div>
        {{ $t('presence.rapport_de_presence') }}
        <br>
        <label class="text-subtitle-1">{{ $t('kiosque.nombre_usagers_en_ligne') }} : {{ presenceFiltré.length }} -
        </label>
        <v-btn
          x-small
          color="blue-grey"
          class="ml-2 white--text"
          @click="onTelechargerRapportPresence()"
        >
          {{ $t('kiosque.telecharger_le_rapport') }}
          <v-icon
            right
            dark
            x-small
          >
            mdi-download
          </v-icon>
        </v-btn>

        <v-checkbox
          v-model="emettreSon"
          class="mt-2"
        >
          <template v-slot:label>
            <span
              class="black--text"
            ><v-icon>mdi-volume-high</v-icon> {{ $t('presence.emettre_un_son_lorsquun_usager_entre_dans_la_salle') }}</span>
          </template>
        </v-checkbox>
      </div>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="presenceFiltré"
      class="elevation-1"
      :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                   pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
    >
      <template v-slot:[`item.tempsPasseMinute`]="{ item }">
        <!-- <v-chip
        color="green"
        dark
      > -->
        <span class="font-weight-bold"> {{ convertirTempsPassé(item.tempsPasseMinute) }}</span>
      <!-- </v-chip> -->
      </template>
      <template v-slot:[`item.data-table-select`]>
        <v-btn
          color="primary"
          dark
          class="ma-2"
        >
          Button
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    name: 'RapportPresence',
    props: {
      salon: String,
      usagersPresents: Array,
      notificationEntree: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      headers: [
        { text: i18n.t('general.nom_de_famille'), value: 'nom' },
        { text: i18n.t('general.prenom'), value: 'prenom' },
        { text: i18n.t('presence.temps_passe_sur_la_page'), value: 'tempsPasseMinute' },
      ],
      présences: [],
      getInfoPresenceInterval: null,
    }),
    computed: {
      presenceFiltré() {
        return this.présences.filter(f => this.usagersPresents?.includes(f.nomUsager))
      },
      emettreSon: {
        get() {
          return this.notificationEntree
        },
        set(val) {
          console.log(val)
          this.$emit('update:notificationEntree', val)
        },
      },
    },
    watch: {

    },
    mounted() {
      this.init()
    },
    beforeDestroy() {
      clearInterval(this.getInfoPresenceInterval)
    },
    methods: {
      init() {
        this.getInfoPresenceInterval = setInterval(() => {
          this.getInfoPrésence()
        }, 10000)
        setTimeout(this.getInfoPrésence(), 2000)
      },
      getInfoPrésence() {
        restApiService
          .get('/api/presences/salon/' + this.salon)
          .then(result => {
            this.présences = result.data
          })
          .catch(error => console.log('Erreur lors du téléchargement du rapport des présences', error))
      },
      onTelechargerRapportPresence() {
        console.log('onTelechargerRapportPresence')
        restApiService.getblob(`/api/presences/telechargement/salon/${this.salon}/rapport-presence.xlsx`).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'rapport-presence.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
          .catch(error => alert(error))
      },
      convertirTempsPassé(tempsEnMinute) {
        return tempsEnMinute > 60 ? `${parseInt(tempsEnMinute / 60)}h ${tempsEnMinute % 60}min` : `${tempsEnMinute} min`
      },
    },
  }
</script>
