var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-badge-account-horizontal-outline"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"font-weight-light card-title mt-2"},[_vm._v(" "+_vm._s(_vm.$t('ateliers'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":_vm.$t('general.chercher'),"hide-details":"","single-line":""},model:{value:(_vm.mots),callback:function ($$v) {_vm.mots=$$v},expression:"mots"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.ateliers,"loading":_vm.loading,"data-cy":"lb-listeAteliers","footer-props":{
                itemsPerPageText: _vm.$t('lignes_par_page'),
                itemsPerPageAllText: _vm.$t('tout'),
                pageText: ("{0}-{1} " + (_vm.$t('general.de')) + " {2}")
              }},scopedSlots:_vm._u([{key:"item.dateDebut",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formaterDate(item.dateDebut))+" ")]}},{key:"item.dateFin",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formaterDate(item.dateFin))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{staticClass:"px-2 ml-1 primary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.onEditerAtelier(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"px-2 ml-1 warning",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.onEffacerAtelier(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)}),_c('v-btn',{staticClass:"primary",attrs:{"data-cy":"btnCreerAtelier"},on:{"click":function($event){return _vm.onAjouterAtelier()}}},[_vm._v(" "+_vm._s(_vm.$t('creer_nouvel_atelier.nouvel_atelier'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }