<template>
  <div>
    <base-material-card
      v-if="!supportTechnique && !masquerFilDiscussion"
      :color="supportTechnique ? 'warning' : 'success'"
      icon="mdi-chat-processing-outline"
      inline
      :title="titre"
      class="px-5 py-3 mb-5"
    >
      <div>
        <comp-chat
          v-if="chaine"
          :str-usager="strUsager"
          :instance="instance"
          :chaine="chaine"
          :formateur="formateur"
          :mode-commentaire="supportTechnique"
          :super-admin="superAdmin"
        >
          <template v-slot:btn-extra>
            <slot name="btn-extra" />
          </template>
        </comp-chat>
      </div>
    </base-material-card>
    <div v-if="supportTechnique">
      <template>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header class="orange darken-2 white--text">
              <span>
                <h3>
                  <v-icon
                    dark
                    medium
                  >
                    mdi-alert
                  </v-icon>

                  {{ $t('diffusion.soutien_technique') }}
                </h3>
              </span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="pt-3">
                <p>
                  {{ $t('diffusion.cette_boite_de_discussion_sert_a_signaler_un_probleme') }}
                </p>
                <p>
                  {{ $t('diffusion.si_vous_éprouvez_un_problème_persistent') }}
                </p>
                <p>
                  {{ $t('diffusion.pour_soumettre_une_question_ou_un_commentaire') }}
                </p>
              </div>
              <div>
                <comp-chat
                  v-if="chaine"
                  :str-usager="strUsager"
                  :instance="instance"
                  :chaine="chaine"
                  :formateur="formateur"
                  :mode-commentaire="supportTechnique"
                  :super-admin="superAdmin"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <br>
    </div>
  </div>
</template>

<script>
  import agoraConfig from './agora-config'
  import CompChat from './CompChat'

  export default {
    components: { CompChat },
    props: {
      chaine: String,
      userInfo: Object,
      formateur: Boolean,
      supportTechnique: Boolean,
      titre: String,
      masquerFilDiscussion: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      panel: 0,
    }),
    computed: {
      instance() {
        return 'orka-' + process.env.VUE_APP_CONTEXTE
      },

      strUsager() {
        const instance = 'orka-' + process.env.VUE_APP_CONTEXTE
        const dtoUsager = agoraConfig.getUsagerDto(instance, this.userInfo)
        // const strUsager = encodeURIComponent(JSON.stringify(dtoUsager))
        return JSON.stringify(dtoUsager)
      },

      superAdmin() {
        return this.$store.state.userInfo?.roles === 'admin'
      },

      url() {
        const instance = 'orka-' + process.env.VUE_APP_CONTEXTE
        const dtoUsager = agoraConfig.getUsagerDto(instance, this.userInfo)
        const strUsager = encodeURIComponent(JSON.stringify(dtoUsager))
        const url = `https://agora.connexence.com/filDiscussion/${instance}/${
        this.chaine
        }?usager=${strUsager}&formateur=${this.formateur}&modeCommentaire=${
        this.supportTechnique
      }&isSuperAdmin=${this.$store.state.userInfo?.roles === 'admin'}`
        return url
      },
    },
  }
</script>

<style>
.frame-discussion {
  width: 100%;
  border-style: none;
  overflow-x: hidden;
}
</style>
