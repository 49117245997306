<template>
  <div v-if="afficherAteliers">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel
        v-model="panel"
        expand
      >
        <v-expansion-panel-header>
          <base-material-card
            color="success"
            icon="mdi-comment-account-outline"
            inline
            :title="$t('diffusion.mes_ateliers')"
            class="px-5 py-3 mb-5"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text style="max-width: 1680px;">
            <v-row>
              <v-col cols="12">
                <v-btn
                  v-if="modeResponsable"
                  color="success"
                  class="mr-2"
                  @click="onRedirectionTous()"
                >
                  {{ $t('diffusion.lancer_la_redirection_pour_tous_les_ateliers') }}
                </v-btn>

                <v-btn
                  v-if="modeResponsable"
                  class="mr-2"
                  color="error"
                  @click="onFermerTous()"
                >
                  {{ $t('diffusion.fermer_tous_les_ateliers') }}
                </v-btn>

                <v-btn
                  v-if="modeResponsable"
                  class="mr-2"
                  @click="modeEdition = !modeEdition"
                >
                  {{ modeEdition ? $t('diffusion.cacher') : $t('diffusion.afficher') }} {{ $t('diffusion.l\'édition_des_ateliers') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-for="atelier in ateliers"
              :key="atelier.id"
            >
              <v-col
                cols="12"
              >
                <v-card>
                  <v-card-title>
                    {{ atelier.nom }}
                    <v-btn
                      v-if="!modeResponsable"
                      color="primary"
                      class="boutonAllerAtelier"
                      @click="onRedirectionSimpleAtelier(atelier)"
                    >
                      {{ $t('diffusion.aller_dans_l\'atelier') }}
                    </v-btn>
                  </v-card-title>
                  <v-card-text style="max-width: 1680px;">
                    <v-row>
                      <v-col>
                        <v-btn
                          v-if="modeResponsable"
                          color="success"
                          class="mr-4 mt-2"
                          @click="onRedirectionAtelier(atelier)"
                        >
                          {{ $t('diffusion.rediriger_tout_le_monde_vers_l\'atelier') }}
                        </v-btn>

                        <v-btn
                          v-if="modeResponsable"
                          color="error"
                          class="mt-2"
                          @click="onFermerAtelier(atelier)"
                        >
                          {{ $t('diffusion.fermer_l\'atelier') }}
                        </v-btn>

                        <v-btn
                          v-if="modeResponsable"
                          color="primary"
                          class="boutonAllerAtelier mt-2"
                          @click="onRedirectionSimpleAtelier(atelier)"
                        >
                          {{ $t('diffusion.aller_dans_l\'atelier') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <div v-if="modeResponsable && modeEdition">
              <liste-ateliers
                :conference="conference"
                @majAteliers="onMajAteliers"
              />
            </div>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import ListeAteliers from './ListeAteliers'
  import restApiService from '@/services/restApiService.js'
  export default {
    components: {
      ListeAteliers,
    },
    props: {
      conference: Object,
      modeResponsable: Boolean,
    },
    data: () => ({
      modeEdition: false,
      ateliers: [],
      afficherAteliers: false,
      panel: 0,
    }),
    mounted () {
      this.getData()
      window.ravm = this
    },
    methods: {
      getData () {
        if (this.modeResponsable) {
          restApiService
            .get(`/api/ateliers/conference/${this.conference.id}`)
            .then(result => {
              this.ateliers = result.data
              this.afficherAteliers = this.ateliers.length > 0
            })
            .catch(erreur => {
              alert(erreur)
            })
        } else {
          restApiService
            .get(`/api/ateliers/conference/${this.conference.id}/usager/${this.$store.state.userInfo.id}`)
            .then(result => {
              this.ateliers = result.data
              this.afficherAteliers = this.ateliers.length > 0
            })
            .catch(erreur => {
              alert(erreur)
            })
        }
      },

      onRedirectionTous () {
        this.ateliers.forEach(atelier => this.onRedirectionAtelier(atelier))
      },
      onFermerTous () {
        this.ateliers.forEach(atelier => this.onFermerAtelier(atelier))
      },

      onMajAteliers (ateliers) {
        this.ateliers = ateliers
      },

      onRedirectionAtelier (atelier) {
        restApiService
          .get('/api/ateliers/redirectionAtelier/' + atelier.id)
          .then(result => {})
          .catch(erreur => {
            alert(erreur)
          })
      },
      onFermerAtelier (atelier) {
        restApiService
          .get('/api/ateliers/fermerAtelier/' + atelier.id)
          .then(result => {
          // this.ateliers = result.data
          // this.count = parseInt(result.headers['x-total-count'])
          })
          .catch(erreur => {
            alert(erreur)
          })
      },
      onRedirectionSimpleAtelier (atelier) {
        const atelierInfo = {
          id: atelier.id,
        }
        restApiService.post('/api/ateliers/redirectionAtelierSimple', atelierInfo).then((res) => {
        })
      },
    },
  }
</script>
<style scoped>
.boutonAllerAtelier{
  float:right;
  margin-left: auto;
}
</style>
