<template>
  <v-card>
    <v-app-bar
      id="appBar"
      fixed
      :collapse="collapse"
      elevate-on-scroll
      inverted-scroll
    >
      <v-btn-toggle
        v-model="tab"
        dense
        color="primary"
        fixed
      >
        <v-btn
          v-if="estResponsable"
          text
          class="boutonMenu"
          @click="onToggleSondage"
        >
          <v-icon left>
            mdi-chart-box-outline
          </v-icon>
          {{ $t('sondage.sondage') }}
        </v-btn>
        <v-btn
          v-if="estResponsable"
          text
          class="boutonMenu"
        >
          <v-icon left>
            mdi-rhombus-split
          </v-icon>
          {{ $t('vision.presence') }}
        </v-btn>
      </v-btn-toggle>
      <v-spacer />
      <!-- <v-btn
        icon
        @click="onStatsQuestion"
      >
        <v-icon>mdi-heart</v-icon>
      </v-btn> -->
      <v-btn
        depressed
        class="orange--text"
        @click="onAfficherAide()"
      >
        <v-icon>
          mdi-human-handsup
        </v-icon>
        {{ $t('general.aide') }}
      </v-btn>

      <div
        id="tabs-i"
        style="position:absolute; left:0px; top:64px; border: 0px solid black;overflow: auto; max-height: 70vh;"
      >
        <v-tabs-items
          v-model="tab"
          style="border: 0px solid red"
        >
          <v-tab-item>
            <div class="pa-6">
              <v-row>
                <v-col>
                  <div style="max-width:180px">
                    <h4>{{ $t('vision.choisir_un_sondages') }}</h4>
                    <v-select
                      v-model="selectionSondage"
                      no-data-text="Aucun sondage de configuré"
                      :items="sondages"
                      item-text="nom"
                      return-object
                      @change="onSelectionSondage"
                    />
                    <h4>
                      {{ $t('vision.choisir_une_question') }}
                    </h4>
                    <v-select
                      v-if="selectionSondage"
                      v-model="selectionQuestion"
                      :items="selectionSondage.listeQuestions"
                      return-object
                      @change="onToggleSondage"
                    >
                      <template v-slot:selection="data">
                        <span class="sansMajuscules">
                          {{ data.item.texte | stripHtml }}
                        </span>
                      </template>
                      <template v-slot:item="data">
                        {{ data.item.texte | maxCar(70) | stripHtml }}
                      </template>
                    </v-select>
                  </div>
                </v-col>
                <v-col>
                  <div
                    style="max-width:220px"
                    class="mx-4 px-4"
                  >
                    <h4>{{ $t('vision.reponses') }}</h4>
                    <div
                      v-for="info in infoChoixRéponse"
                      :key="info.id"
                    >
                      <div class="text-caption">
                        <span class="">{{ info.texte | stripHtml | maxCar(120) }} :</span> <strong> {{ info.nombreRéponses }} - {{ pourcentageRéponse(info, infoChoixRéponse) + "%" }}</strong>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div style="width:180px">
                    <h4 class="ml-10 mb-2">
                      {{ $t('vision.graph') }}
                    </h4>
                    <chartist
                      class="ct-chart ct-square"
                      :data="chartChoix.data"
                      :options="chartChoix.options"
                      type="Bar"
                      style="max-height: 150px;"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-btn
                  fab
                  small
                  class="mr-3 mb-3"
                  :disabled="!selectionQuestion"
                  @click="onQuestionPrécédente()"
                >
                  <v-icon>mdi-arrow-left-bold</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  class="mr-3 mb-3"
                  :disabled="!selectionQuestion"
                  @click="onQuestionSuivante"
                >
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
                <v-btn-toggle
                  v-model="etatAffichageQuestion"
                  dense
                >
                  <v-btn
                    text
                    class="primary"
                    :disabled="!selectionQuestion"
                    :small="$vuetify.breakpoint.mobile"
                    @click="onAfficherQuestion()"
                  >
                    {{ $t('vision.publier_question') }}
                  </v-btn>
                  <v-btn
                    text
                    :disabled="!selectionQuestion"
                    :small="$vuetify.breakpoint.mobile"
                    @click="onMasquerQuestion()"
                  >
                    {{ $t('vision.masquer_question') }}
                  </v-btn>
                  <v-btn
                    text
                    :disabled="!selectionQuestion"
                    :small="$vuetify.breakpoint.mobile"
                    @click="onAfficherResultat()"
                  >
                    {{ $t('vision.partager_resultats') }}
                  </v-btn>
                </v-btn-toggle>
              </v-row>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div
              style="width:600px"
              class="elevation-24"
            >
              <rapport-presence
                ref="rapportPresence"
                :salon="chaineConference"
                :usagers-presents="listeUsagersPrésents"
                :notification-entree.sync="notificationSonore"
              />
            </div>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title>Gamma</v-card-title>
              <v-card-text>
                Pong
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-app-bar>
    <v-dialog
      v-model="dialogueSoutien"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('vision.soutien') }}
        </v-card-title>

        <v-card-text v-html="conference.evenement.infoSoutien" />

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialogueSoutien = false"
          >
            {{ $t('vision.merci') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
#appBar {
  transition: all 1s;
}
@media screen and (max-width: 600px) {
  #appBar {
    left:0px !important;
    right:0px !important;
  }
}
@media screen and (min-width: 900px) {
  #appBar {
    left:40% !important;
    right:17% !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  #appBar {
    left:20% !important;
    right:25% !important;
  }
}

.boutonMenu{
  margin: 0 !important;
}

.sansMajuscules {
  text-transform: none;
}
</style>

<style>
svg.ct-chart-bar, svg.ct-chart-line{
  overflow: visible;
}
.ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space:nowrap;
  font-size: 11px;
  right: -20px;
  top:-2px;
}
</style>

<script>
  import notificationSonore from '@/services/notificationSonore.js'
  import restApiService from '@/services/restApiService.js'
  import Salon from '@/services/salon'
  import ToucanCom from '@/services/toucanCom'
  import Gestionpresence from '@/services/gestionpresence.js'
  import RapportPresence from '../composantes/RapportPresence.vue'
  import i18n from '@/i18n.js'
  export default {
    filters: {
      stripHtml: function (value) {
        if (value === undefined) {
          return ''
        }
        return value.replace(/<[^>]*>?/gm, '')
          .replace('&nbsp;', '')
      },
      maxCar: function (value, m) {
        if (value.length > m) {
          return value.substring(0, m) + '...'
        }
        return value
      },

    },
    components: {
      RapportPresence,
    },
    props: {
      conference: Object,
    },
    data: () => ({
      sondages: {},
      etatAffichageQuestion: null,
      selectionSondage: null,
      selectionQuestion: null,
      tab: null,
      collapse: false,
      salon: null,
      infoChoixRéponse: [],
      dialogueSoutien: false,
      estResponsable: false,
      chartChoix: {
        data: {
          labels: [],
          series: [
            [],
          ],
        },
        options: {
          onlyInteger: true,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      notificationSonore: false,
      listePrésencesActives: [],
      gestionPresence: null,
      dernierAppel: 0,
      getSalonPrevu: false,
    }),
    computed: {
      chaineConference () {
        const chaine = 'ORKA-C-' + this.conference.id
        return chaine
      },
      listeUsagersPrésents() {
        // console.log('listeUsagersPrésents', this.listePrésencesActives.map(u => u.usager.nomUsager))
        return this.listePrésencesActives.map(u => u.usager.nomUsager)
      },
    },
    created() {
      window.addEventListener('beforeunload', this.leaving)
    },
    beforeDestroy() {
      this.gestionPresence.arreter()
      this.salon.sortir()
      this.gestionPresence = null
    },
    mounted () {
      this.estResponsable = this.$store.state.compteClient !== undefined
      this.salon = Salon.initConference(this.conference.id)
      this.salon.entrer('VisionOutil')
      if (this.estResponsable) {
        this.salon.ajouterNotificationEntréeSortie(this.onEntreeSortie)
        ToucanCom.getInstance().enregistrerActionNotification('statsQuestion', this.onStatsQuestion)
        restApiService.get('/api/sondages/compteClient/' + this.$store.state.compteClient.id).then((res) => {
          this.sondages = res.data
          if (this.sondages.length > 0) {
            this.selectionSondage = this.sondages[0]
            if (this.selectionSondage.listeQuestions.length > 0) {
              this.selectionQuestion = this.selectionSondage.listeQuestions[0]
            }
          }
        })
      }
      this.hideAndShowAppbar()
      this.initGestionPrésence()
    },
    methods: {

      pourcentageRéponse(info, infoChoixRéponse) {
        // caculer le total de nombreRéponses dans infoChoixRéponse
        var total = infoChoixRéponse.reduce((acc, cur) => {
          return acc + cur.nombreRéponses
        }, 0)
        return Math.round(info.nombreRéponses / total * 100)
      },
      leaving() {
        this.gestionPresence.arreter()
        this.gestionPresence = null
      },
      initGestionPrésence() {
        if (!this.gestionPresence) {
          this.gestionPresence = new Gestionpresence(this.$store.state.userInfo.id, this.chaineConference)
          this.gestionPresence.demarrer()
        }
      },
      onAfficherQuestion () {
        restApiService.get(`/api/salons/${this.salon.identifiant}/afficherQuestion/${this.selectionQuestion.id}`).then((res) => {
          this.$dialog.notify.success(i18n.t('vision.question_publiee'))
        })
      },
      onSelectionSondage () {
        this.selectionQuestion = this.selectionSondage.listeQuestions[0]
        this.onToggleSondage()
      },
      onMasquerQuestion () {
        restApiService.get(`/api/salons/${this.salon.identifiant}/masquerQuestion/${this.selectionQuestion.id}`).then((res) => {
          this.etatAffichageQuestion = null
          this.$dialog.notify.success(i18n.t('vision.question_masquee'))
        })
      },
      onAfficherResultat () {
        restApiService.get(`/api/salons/${this.salon.identifiant}/afficherQuestionResultat/${this.selectionQuestion.id}`).then((res) => {
          this.$dialog.notify.success(i18n.t('vision.resultats_partages'))
        })
      },
      majStats (info) {
        // this.chartReponsesParSec.data.labels = info.listeInfoRéponsesParSeconde.map(i => i.seconde.toString())
        // this.chartReponsesParSec.data.series = [info.listeInfoRéponsesParSeconde.map(i => i.nombreRéponses)]
        this.chartChoix.data.labels = info.listeInfoChoix.map(i => {
          return this.selectionQuestion.listeChoix.find(
            c => c.id === i.idChoix)
            .texte.replace(/<[^>]*>?/gm, '').replace('&nbsp;', '').substring(0, 20)
        })
        this.chartChoix.data.series = [info.listeInfoChoix.map(i => i.nombreRéponses)]
        this.infoChoixRéponse = info.listeInfoChoix.map(s => ({
          id: s.id,
          texte: this.selectionQuestion.listeChoix.find(c => c.id === s.idChoix).texte,
          nombreRéponses: s.nombreRéponses,
        }))
      },
      onStatsQuestion (s) {
        this.majStats(s.info)
      },
      onToggleSondage () {
        restApiService.get(`/api/sondage-questions/${this.selectionQuestion.id}/conference/${this.conference.id}`).then((r) => {
          this.majStats(r.data)
        })
      },
      onQuestionPrécédente () {
        const idx = this.selectionSondage.listeQuestions.indexOf(this.selectionQuestion)
        if (idx === 0) {
          return
        }
        this.selectionQuestion = this.selectionSondage.listeQuestions[idx - 1]
        this.etatAffichageQuestion = null
        this.onToggleSondage()
      },
      onQuestionSuivante () {
        const idx = this.selectionSondage.listeQuestions.indexOf(this.selectionQuestion)
        if (idx >= this.selectionSondage.listeQuestions.length - 1) {
          return
        }
        this.selectionQuestion = this.selectionSondage.listeQuestions[idx + 1]
        this.etatAffichageQuestion = null
        this.onToggleSondage()
      },
      onAfficherAide () {
        this.dialogueSoutien = true
      },
      hideAndShowAppbar () {
        var appBar = document.getElementById('appBar')
        var myScrollFunc = function () {
          var y = window.scrollY
          if (y <= 70 && window.matchMedia('(max-width: 600px)').matches) {
            appBar.style = 'opacity:0;top:-100%;'
          } else {
            appBar.style = 'opacity:1;top:0px;position:fixed;'
            document.getElementById('tabs-i').style.top = appBar.offsetHeight + 'px'
          }
        }
        myScrollFunc()
        window.addEventListener('scroll', myScrollFunc)
      },
      onEntreeSortie(e) {
        if (e?.action === 'entrer' && this.notificationSonore === true) {
          notificationSonore.jouer()
        }

        const maintenant = new Date().getTime()
        const cinqSecondes = 5 * 1000
        if (maintenant - this.dernierAppel >= cinqSecondes) {
          this.onGetSalon()
        } else if (!this.getSalonPrevu) {
          this.getSalonPrevu = true
          setTimeout(this.onGetSalon, cinqSecondes - (maintenant - this.dernierAppel))
        }
      },
      onGetSalon() {
        this.getSalonPrevu = false
        this.salon.getSalon().then(info => {
          this.dernierAppel = new Date().getTime()
          this.listePrésencesActives = info.listePrésences
            this.$refs.rapportPresence?.getInfoPrésence()
        })
      },
    },
  }
</script>
