<template>
  <div
    v-if="conference.priseParole"
    class="d-inline-block"
  >
    <v-btn
      class="success"
      :disabled="priseParoles.length > 0"
      @click="demanderParole"
    >
      <v-icon class="mr-2">
        mdi-microphone
      </v-icon>&#160;{{ $t('prise_parole.demander') }}
    </v-btn>
    <span v-if="priseParoles.length > 0">
      {{ $t('prise_parole.demande.en_attente') }} - <a @click="annuler">{{ $t('prise_parole.demande.annuler') }}</a>
    </span>
  </div>
</template>

<script>
  import priseParole from '@/services/priseParole.js'
  import ecouteur from '@/services/ecouteur.js'
  import i18n from '@/i18n.js'
  export default {
    props: {
      conference: Object,
    },

    data: () => ({
      priseParoles: [],
      idEcouteurPriseParole: null,
    }),

    mounted() {
      this.initEcouteurPriseParole()
      this.initEtat()
    },

    destroyed() {
      if (this.idEcouteurPriseParole) {
        ecouteur.retirerEcouteurUsager(this.idEcouteurPriseParole)
      }
    },

    methods: {
      demanderParole() {
        priseParole.demander(this.conference.id)
          .then(() => {
            this.priseParoles.push({ id: 'new' })
          })
          .catch(() => {
            this.initEtat()
            this.$dialog.warning(
              {
                title: i18n.t('general.attention'),
                text: i18n.t('prise_parole.en_attente'),
                actions: this.okButtonText,
                waitForResult: false,
              },
            )
          })
      },

      annuler() {
        priseParole.annuler(this.conference.id)
          .then(() => {
            this.priseParoles.splice(0)
          })
      },

      initEtat() {
        //   $store.state.userInfo
        priseParole.getPriseParoles(this.conference.id, true)
          .then(({ data }) => {
            console.log('Init prise paroles', data)
            this.priseParoles.splice(0)
            data.forEach(p => {
              this.priseParoles.push(p)
            })
          })
      },

      initEcouteurPriseParole() {
        const nomUsager = this.$store.state.userInfo.nomUsager
        this.idEcouteurPriseParole = ecouteur.ecouterUsager(nomUsager, data => {
          if (data.action === 'accordePriseParole') {
            this.accuserReception()
            this.onAccorde()
          } else if (data.action === 'refusePriseParole') {
            this.onRefuse()
          }
        })
      },

      onAccorde() {
        console.log('La prise de parole a été accordée!')
        this.priseParoles.splice(0)
        this.$dialog.confirm({
          title: i18n.t('prise_parole.confirmer.titre'),
          text: i18n.t('prise_parole.confirmer.texte'),
          actions: {
            false: i18n.t('prise_parole.confirmer.annuler'),
            true: {
              color: 'green',
              text: i18n.t('prise_parole.confirmer.ok'),
            },
          },
        }).then((res) => {
          if (res) {
            this.$emit('redirection')
            window.open(this.conference.urlPriseParole, '_blank').focus()
            // A bug makes it so we need this log for the dialog to close.
            // Do not remove me unless you've found a fix.
            console.log('Closing the dialog...')
          } else {
            console.log("On annule après l'accord...")
            priseParole.annuler(this.conference.id, true)
          }
        })
      },

      onRefuse() {
        console.log('La prise de parole a été refusée!')
        this.priseParoles.splice(0)
        this.$dialog.warning(
          {
            title: i18n.t('general.attention'),
            text: i18n.t('prise_parole.refuse'),
            actions: this.okButtonText,
            waitForResult: false,
          },
        )
      },

      accuserReception() {
        priseParole.accuserReception(this.conference.id)
      },
    },
  }
</script>

<style scoped>
/* this style is unused, but it looks cool so I'm keeping it around in case */
.pulse-button {
  box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.5);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  70% {
    box-shadow: 0 0 0 20px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}
</style>
