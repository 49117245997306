<template>
  <base-material-card
    color="success"
    icon="mdi-chat-processing-outline"
    inline
    :title="$t('prise_parole.gestion.titre')"
  >
    <v-checkbox
          v-model="afficherHistorique"
          :label="$t('prise_parole.gestion.afficher_historique')"
    />
    <v-data-table
      :headers="headers"
      :items="priseParolesFiltrees"
      item-key="id"
      :footer-props="{
        itemsPerPageText: $t('lignes_par_page'),
        itemsPerPageAllText: $t('tout'),
        pageText: `{0}-{1} ${$t('general.de')} {2}`
      }"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ formatHeure(item.dateDemande) }}
      </template>
      <template v-slot:[`item.nom`]="{ item }">
        {{ `${item.usager.prenom} ${item.usager.nom}` }}
      </template>
      <template
        v-if="afficherHistorique"
        v-slot:[`item.statut`]="{ item }"
      >
        {{ getStatut(item) }}
      </template>
      <template v-slot:[`item.accorder`]="{ item }">
        <v-btn
          v-if="isNouveau(item)"
          icon
          class="success"
          @click="accorder(item)"
        >
          <v-icon>mdi-microphone</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.refuser`]="{ item }">
        <v-btn
          v-if="isNouveau(item)"
          icon
          class="red"
          @click="refuser(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </base-material-card>
</template>

<script>
  import service from '@/services/priseParole.js'
  import ecouteur from '@/services/ecouteur.js'
  import i18n from '@/i18n.js'

  export default {
    props: {
      conference: Object,
    },

    data: () => ({
      priseParoles: [],
      ecouteurId: null,
      delaiAccuseReceptionId: null,
      usagerAccuseReception: null,

      afficherHistorique: false,
    }),

    computed: {
      chaine() {
        return `ORKA-C-${this.conference.id}`
      },

      priseParolesFiltrees() {
        if (this.afficherHistorique) return this.priseParoles
        return this.priseParoles.filter(p => !p.dateAccorde && !p.dateRefuse && !p.dateAnnule)
      },

      headers() {
        const headers = [
          { text: this.$t('prise_parole.gestion.date'), value: 'date', sortable: false },
          { text: this.$t('prise_parole.gestion.nom'), value: 'nom', sortable: false },
          { text: this.$t('prise_parole.gestion.statut'), value: 'statut', sortable: false },
          { text: this.$t('prise_parole.gestion.accorder'), value: 'accorder', sortable: false },
          { text: '', value: 'refuser', sortable: false },
        ]

        if (!this.afficherHistorique) {
          headers.splice(2, 1)
        }

        return headers
      },
    },

    mounted() {
      this.init()
    },

    destroyed() {
      this.retirerEcouteur()
    },

    methods: {
      init() {
        this.refresh()
        this.ecouteurId = ecouteur.ecouterChaine(this.chaine, (msg) => {
          console.log(msg.action)
          if (msg.action === 'accuseReceptionPriseParole') {
            console.log('Accusé réception reçu!', msg)
            if (this.usagerAccuseReception) {
              console.log('On a un usager!', this.usagerAccuseReception)
              if (msg.info.usager.nomUsager === this.usagerAccuseReception.nomUsager) {
                console.log('On efface le timeout de réception!')
                this.usagerAccuseReception = null

                clearTimeout(this.delaiAccuseReceptionId)
                this.delaiAccuseReceptionId = null
              }
            }
          } if (msg.action === 'annuleDejaAccordePriseParole') {
            console.log('msg.action -> annuleDejaAccordePriseParole')
            setTimeout(this.refresh, 200)
            const usager = msg.info.usager
            this.$dialog.warning(
              {
                title: i18n.t('general.attention'),
                text: i18n.t('prise_parole.demande.accorde_puis_annuler') + `${usager.prenom} ${usager.nom}`,
                actions: this.okButtonText,
                waitForResult: false,
              },
            )
          } else if (msg.action.indexOf('PriseParole') >= 0) {
            setTimeout(this.refresh, 200)
          }
        })
      },

      retirerEcouteur() {
        ecouteur.retirerEcouteurChaine(this.chaine, this.ecouteurId)
      },

      refresh() {
        service.getPriseParoles(this.conference.id).then(({ data }) => {
          this.priseParoles.splice(0)
          data.reverse().forEach(item => {
            this.priseParoles.push(item)
          })
        })
      },

      formatHeure(date) {
        return this.$moment(date).format('HH:mm')
      },

      accorder(priseParole) {
        service.accorder(priseParole.id)
          .then(() => {
            this.attendreAccuseReception(priseParole.usager)
          })
      },

      refuser(priseParole) {
        service.refuser(priseParole.id)
      },

      attendreAccuseReception(usager) {
        this.usagerAccuseReception = usager
        this.delaiAccuseReceptionId = setTimeout(() => {
          this.$dialog.warning(
            {
              title: i18n.t('general.attention'),
              text: i18n.t('prise_parole.accuse_reception.manquant') + `${usager.prenom} ${usager.nom}`,
              actions: this.okButtonText,
              waitForResult: false,
            },
          )
        }, 8 * 1000)
      },

      getStatut(p) {
        if (p.dateAnnule) {
          return this.$t('prise_parole.gestion.statuts.annule')
        } else if (p.dateRefuse) {
          return this.$t('prise_parole.gestion.statuts.refuse')
        } else if (p.dateAccorde) {
          return this.$t('prise_parole.gestion.statuts.accorde')
        }
        return this.$t('prise_parole.gestion.statuts.nouveau')
      },

      isNouveau(p) {
        return !p.dateAccorde && !p.dateRefuse && !p.dateAnnule
      },

    },
  }
</script>

<style scoped>
</style>
