<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-card icon="mdi-badge-account-horizontal-outline">
      <template v-slot:after-heading>
        <div class="font-weight-light card-title mt-2">
          {{ $t('ateliers') }}
        </div>
      </template>
      <v-text-field
        v-model="mots"
        append-icon="mdi-magnify"
        class="ml-auto"
        :label="$t('general.chercher')"
        hide-details
        single-line
        style="max-width: 250px;"
      />

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="ateliers"
        :loading="loading"
        data-cy="lb-listeAteliers"
         :footer-props="{
                  itemsPerPageText: $t('lignes_par_page'),
                  itemsPerPageAllText: $t('tout'),
                  pageText: `{0}-{1} ${$t('general.de')} {2}`
                }"
      >
        <template v-slot:[`item.dateDebut`]="{ item }">
          {{ formaterDate(item.dateDebut) }}
        </template>
        <template v-slot:[`item.dateFin`]="{ item }">
          {{ formaterDate(item.dateFin) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="px-2 ml-1 primary"
            min-width="0"
            small
            @click="onEditerAtelier(item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            class="px-2 ml-1 warning"
            min-width="0"
            small
            @click="onEffacerAtelier(item)"
          >
            <v-icon small>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        class="primary"
        data-cy="btnCreerAtelier"
        @click="onAjouterAtelier()"
      >
        {{ $t('creer_nouvel_atelier.nouvel_atelier') }}
      </v-btn>
    </base-material-card>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import i18n from '@/i18n.js'

  export default {
    props:
      {
        conference: Object,
      },
    data: () => ({
      loading: true,
      count: 0,
      search: undefined,
      mots: '',
      ateliers: [],
      options: {},
      headers: [
        { text: i18n.t('nom_commun'), value: 'nom' },
        { text: i18n.t('general.date_de_debut'), value: 'dateDebut' },
        { text: i18n.t('general.date_de_fin'), value: 'dateFin' },
        { text: i18n.t('actions'), value: 'actions' },
      ],
    }),
    computed: {
    },
    watch: {
      conference: {
        handler (newValue) {
          this.getData()
        },
      },
    },
    methods: {
      getData () {
        if (!this.conference.id) {
          return
        }
        restApiService
          .get(`/api/ateliers/conference/${this.conference.id}`)
          .then(result => {
            this.ateliers = result.data
            // this.count = parseInt(result.headers['x-total-count'])
            this.loading = false
            this.$emit('majAteliers', this.ateliers)
          })
          .catch(erreur => {
            alert(erreur)
            this.loading = false
          })
      },
      async onAjouterAtelier () {
        const nouvelAtelier = {
          nom: '',
          conference: this.conference,
        }
        const reponse = await this.$dialog.prompt({
          title: i18n.t('creer_nouvel_atelier.nouvel_atelier'),
          text: i18n.t('creer_nouvel_atelier.saisir_nom_atelier'),
          rules: [
            v => !!v || i18n.t('champ_requis'),
          ],
          textField: {
            type: 'text',
            id: 'nouvelAtelierNomlInput',
          },
          actions: {
            false: i18n.t('annuler'),
            true: {
              color: 'red',
              text: i18n.t('confirmer'),
              handle: () => {
                return new Promise(resolve => {
                  nouvelAtelier.nom = document.getElementById('nouvelAtelierNomlInput').value
                  if (nouvelAtelier.nom) {
                    resolve(true)
                  } else { resolve(false) }
                })
              },
            },
          },
        })

        if (reponse) {
          restApiService
            .post('/api/ateliers/', nouvelAtelier)
            .then(result => {
              this.$router.push({ path: `/gestion/editionAtelier/${result.data.id}` })
            })
            .catch(erreur => alert(erreur))
        }
      },
      onEditerAtelier (atelier) {
        this.$router.push({ path: `/gestion/editionAtelier/${atelier.id}` })
      },
      onEffacerAtelier (atelier) {
        this.$dialog
          .confirm({
            text: i18n.t('confirmation_suppression_element'),
            title: this.$t('attention'),
          })
          .then(r => {
            if (r) {
              restApiService
                .delete('/api/ateliers/' + atelier.id)
                .then(result => {
                  this.getData()
                })
                .catch(erreur => alert(erreur))
            }
          })
      },
    },
  }
</script>
