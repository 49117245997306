<template>
  <div>
    <iframe
      class="frame-gestion-question"
      :src="url"
      height="740px"
    />
  </div>
</template>

<script>
  import agoraConfig from './agora-config'

  export default {
    props: { chaine: String, userInfo: Object },
    computed: {
      url () {
        const instance = 'orka-' + process.env.VUE_APP_CONTEXTE

        const dtoUsager = agoraConfig.getUsagerDto(instance, this.userInfo)
        const strUsager = encodeURIComponent(JSON.stringify(dtoUsager))
        var url = `https://agora.connexence.com/gestionQuestion/fr/${instance}/${this.chaine}?usager=${strUsager}`

        if (this.$store.state.appLanguage === 'en') {
          url = `https://agora.connexence.com/gestionQuestion/en/${instance}/${this.chaine}?usager=${strUsager}`
        }

        return url
      },
    },
  }
</script>

<style>
.frame-gestion-question {
  width: 100%;
  border-style: none;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
