import restApiService from './restApiService'

export default {
    demander(idConference) {
        return restApiService.post(`/api/prise-paroles/demander/${idConference}`)
    },

    annuler(idConference, dejaAccorde = false) {
        return restApiService.post(`/api/prise-paroles/annuler/${idConference}?dejaAccorde=${dejaAccorde}`)
    },

    accuserReception(idConference) {
        return restApiService.post(`/api/prise-paroles/accuserReception/${idConference}`)
    },

    accorder(idPriseParole) {
        return restApiService.post(`/api/prise-paroles/accorder/${idPriseParole}`)
    },

    refuser(idPriseParole) {
        return restApiService.delete(`/api/prise-paroles/${idPriseParole}`)
    },

    getPriseParoles(idConference, filtrerPourUsager = false) {
        return restApiService.get(`/api/conferences/${idConference}/prise-paroles?filtrerPourUsager=${filtrerPourUsager}`)
    },
}
