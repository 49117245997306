<style>
.fil-discussion {
  overflow: hidden;
}
.messages {
  height: 400px;
  overflow-y: scroll;
  padding-top: 8px;
  overflow-x: hidden;
}

.message-root {
  border-bottom: 1px solid #dadada;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.reponse-root {
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  margin-left: 5px;
  padding-left: 8px;
  border-left: 4px solid #dadada;
}

.message-entente {
  width: 120px;
  text-overflow: ellipsis;
  /* flex-grow: 1; */
}

.message-cadre {
  max-width: calc(100% - 240px);
  flex-grow: 1;
  padding: 0 10px;
}

.message-action {
  width: 120px;
  text-align: right;
  padding-right: 8px;
}

.message-cadre > span {
  float: left;
}

.message-contenu {
  white-space: pre-wrap;
  margin: 0px 4px 10px;
}

.message-controle {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: block;
}

.message-controle-root {
  border-top: 1px solid #dadada;
}

.message-saisie {
  display: inline !important;
  margin-bottom: 10px;
}

.message-envoyer {
  display: inline !important;
  position: relative;
}

.message-selection {
  width: 140px;
  display: inline !important;
}

.message-suppression {
  width: 250px;
  display: inline !important;
}

span + span {
  margin-left: 5px;
}

.fas.fa-thumbs-up:hover,
.fas.fa-times-circle:hover {
  opacity: 0.75;
  cursor: pointer;
}

.fas.fa-times-circle {
  color: red;
}

.super-admin {
  color: #2989d8;
}

.message-controle .btn + .btn {
  margin-left: 10px;
}

.emettre-son {
  margin-top: 10px;
}

.emettre-son input {
  margin-right: 10px;
}
.messages-mobile .message-entente {
  flex-grow: 1;
}

.messages-mobile .message-cadre {
  flex: 0 0 60%;
}

.messages-mobile .message-controle {
  position: absolute !important;
  bottom: 0 !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.message-reponse-section {
  width: 100%;
}

.ig-reponse {
  width: 100%;
}

.txt-reponse {
}

.igb-envoi-reponse {
  position: relative;
  left: -36px;
}

.btn-envoi-reponse {
  background-color: transparent;
  border: 0;
  padding: 8px;
  z-index: 12000 !important;
}
</style>

<template id="fil-discussion">
  <div class="fil-discussion">
    <div :style="mobile ? 'height: calc(100vh - 75px);' : ''">
      <div class="row">
        <div class="col-sm-12">
          <div
            ref="messages"
            :class="mobile ? 'messages messages-mobile' : 'messages'"
          >
            <v-row
              v-for="message in messagesFiltres"
              :key="message.id"
              class="message-root row"
            >
              <v-col class="message-entente col-6 col-sm-3 col-md-2 col-lg-2 pb-0">
                <span
                  :class="message.formateur ? 'blue--text text--darken-2' : ''"
                >
                  <v-icon
                    :class="
                      message.formateur ? 'blue--text text--darken-2' : ''
                    "
                  >mdi-account-circle</v-icon>
                  &#160;{{
                    auteurs[message.auteur]
                      ? auteurs[message.auteur].pseudo
                      : ''
                  }}
                </span>
                <br>
                <v-icon>mdi-clock-outline</v-icon>
                &#160;{{ message.date | toHours }}
              </v-col>
              <v-col class="col-7 col-sm-6 col-md-8 col-lg-8">
                <p class="message-contenu">
                  <span v-if="message.type == 'question'">
                    <v-icon>mdi-help-circle-outline</v-icon>&#160;
                  </span>
                  <span>{{ message.contenu.trim() }}</span>
                </p>
              </v-col>
              <v-col class="col-5 col-sm-3 col-md-2 col-lg-2 d-flex justify-end pr-6">
                <span
                  v-if="formateur"
                  @click="repondre(message)"
                >
                  <v-icon>mdi-reply</v-icon>
                </span>
                <span
                  :class="
                    message.listeLikes.indexOf(monId) >= 0
                      ? 'blue--text text--darken-2'
                      : ''
                  "
                  @click="like(message.id)"
                >
                  <v-icon
                    :class="
                      message.listeLikes.indexOf(monId) >= 0
                        ? 'blue--text text--darken-2'
                        : ''
                    "
                  >mdi-thumb-up</v-icon>
                  &#160;{{ message.listeLikes.length }}
                </span>
                <span
                  v-if="message.auteur == monId || formateur"
                  @click="supprimer(message.id)"
                >
                  <v-icon class="red--text">mdi-delete-forever</v-icon>
                </span>
              </v-col>

              <!-- DEBUT: REPONSE -->
              <div class="break" />
              <v-row
                v-for="reponse in reponsesPour(message)"
                :key="reponse.id"
                class="reponse-root"
              >
                <v-col class="message-entente col-5 col-sm-3 col-md-2 col-lg-2 pb-0">
                  <span
                    :class="
                      reponse.formateur ? 'blue--text text--darken-2' : ''
                    "
                  >
                    <v-icon
                      :class="
                        reponse.formateur ? 'blue--text text--darken-2' : ''
                      "
                    >mdi-account-circle</v-icon>
                    &#160;{{
                      auteurs[reponse.auteur]
                        ? auteurs[reponse.auteur].pseudo
                        : ''
                    }}
                  </span>
                  <br>
                  <v-icon>mdi-clock-outline</v-icon>
                  &#160;{{ reponse.date | toHours }}
                </v-col>
                <v-col class="col-8 col-sm-6 col-md-8 col-lg-8">
                  <p class="message-contenu">
                    <span>{{ reponse.contenu.trim() }}</span>
                  </p>
                </v-col>
                <v-col class="col-4 col-sm-3 col-md-2 col-lg-2 d-flex justify-end pr-9">
                  <span
                    :class="
                      reponse.listeLikes.indexOf(monId) >= 0
                        ? 'blue--text text--darken-2'
                        : ''
                    "
                    @click="like(reponse.id)"
                  >
                    <v-icon
                      :class="
                        reponse.listeLikes.indexOf(monId) >= 0
                          ? 'blue--text text--darken-2'
                          : ''
                      "
                    >mdi-thumb-up</v-icon>
                    &#160;{{ reponse.listeLikes.length }}
                  </span>
                  <span
                    v-if="reponse.auteur == monId || formateur"
                    @click="supprimer(reponse.id)"
                  >
                    <v-icon class="red--text">mdi-delete-forever</v-icon>
                  </span>
                </v-col>
              </v-row>

              <div class="break" />
              <div
                v-if="isAfficherRepondre(message)"
                class="message-reponse-section"
              >
                <v-textarea
                  v-model="nouvellesReponses[message.id]"
                  auto-grow
                  outlined
                  rows="1"
                  @keydown.enter.exact.prevent
                  @keyup.enter.exact="envoyerReponse(message)"
                >
                  <template v-slot:append>
                    <v-btn
                      icon
                      outlined
                      color="blue"
                      class="mb-3"
                      @click="envoyerReponse(message)"
                    >
                      <v-icon>
                        mdi-send
                      </v-icon>
                    </v-btn>
                  </template>
                </v-textarea>
              </div>

              <!-- FIN: REPONSE -->
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div class="message-controle-root">
      <div class="message-controle">
        <div v-if="!typeNouveauMessage && !modeCommentaire">
          <v-btn
            class="primary my-2"
            @click="selectionnerTypeMessage('question')"
          >
            <v-icon class="mr-2">
              mdi-help-circle
            </v-icon>{{ $t('general.question') }}
          </v-btn>
          <v-btn
            class="yellow darken-3 my-2"
            @click="selectionnerTypeMessage('commentaire')"
          >
            <v-icon class="mr-2">
              mdi-message
            </v-icon>{{ $t('general.commentaire') }}
          </v-btn>
          <v-btn
            v-if="formateur"
            class="blue my-2"
            :href="urlTelechargement"
            target="_blank"
            style="padding: 8px;"
          >
            <v-icon class="mr-2">
              mdi-download-circle
            </v-icon>&#160;{{ $t('discussion.exporter_la_discussion') }}
          </v-btn>
          <v-btn
            v-if="isSuperAdmin"
            @click="supprimerTousLesMessage()"
          >
            {{ $t('discussion.supprimer_tous_les_messages') }}
          </v-btn>
          <slot name="btn-extra" />
        </div>
        <div v-else>
          <p v-if="!modeCommentaire">
            {{
              typeNouveauMessage == 'question' ? $t('diffusion.posez_une_question') : $t('diffusion.ecrire_un_commentaire')
            }}
          </p>
          <v-textarea
            v-model="nouveauMessage"
            rows="1"
            auto-grow
            outlined
            @keydown.enter.exact.prevent
            @keyup.enter.exact="envoyerMessage"
          />
          <v-btn
            class="primary"
            @click="envoyerMessage"
          >
            <i class="fas fa-paper-plane" />&#160;{{ $t('diffusion.envoyer') }}
          </v-btn>

          <v-btn
            v-if="isSuperAdmin"
            @click="supprimerTousLesMessage()"
          >
            <i class="fas fa-comment-dots" />&#160;{{ $t('discussion.supprimer_tous_les_messages') }}
          </v-btn>

          <v-btn
            v-if="!modeCommentaire"
            id="btnAnnuler"
            class="btn btn-danger message-envoyer"
            @click="annulerSaisie"
          >
            <i class="fas fa-window-close" />&#160;{{ $t('general.annuler') }}
          </v-btn>
        </div>

        <div
          v-if="!modeCommentaire"
          class="emettre-son"
        >
          <v-checkbox
            v-model="emettreSon"
            @click="toggleEmettreSon()"
          >
            <template v-slot:label>
              <span
                class="black--text"
              ><v-icon>mdi-volume-high</v-icon> {{ $t("kiosque.émettre_un_son_lorsqu'un_nouveau_message_est_reçu") }}</span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import notificationSonore from '@/services/notificationSonore.js'
  import axios from 'axios'
  import Vue from 'vue'
  import ToucanCom from '@/services/toucanCom'

  const baseServeur = process.env.VUE_APP_SERVEUR_AGORA
    ? process.env.VUE_APP_SERVEUR_AGORA
    : 'https://agora.connexence.com'

  Vue.filter('toHours', function(value) {
    if (!value) return ''
    const date = new Date(value)

    const heure = ('0' + date.getHours()).slice(-2)
    const minute = ('0' + date.getMinutes()).slice(-2)

    return heure + ':' + minute
  })

  export default {
    props: {
      instance: String,
      chaine: String,
      strUsager: String,
      formateur: Boolean,
      modeCommentaire: Boolean,
      superAdmin: Boolean,
    },

    data: function() {
      return {
        hasInit: false,
        messages: [],
        auteurs: {},
        nouveauMessage: '',
        typeNouveauMessage: '',
        monId: '',
        emettreSon: false,
        ws: null,
        mobile: false,
        isSuperAdmin: false,
        nouvellesReponses: {},
        dateDerniereNotification: new Date(),
      }
    },
    computed: {
      urlTelechargement() {
        return `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/telecharger`
      },

      messagesFiltres: function() {
        return this.messages.filter(this.filtreMsgRoot)
      },
    },

    watch: {
      chaine(newval, oldval) {
        this.init()
      },
    },

    beforeDestroy() {
      this.ws.close(4001)
    },

    mounted() {
      if (!this.modeCommentaire) {
        window.vmChat = this
      }

      this.init()
      ToucanCom.getInstance().enregistrerActionNotification('majEmettreSon', this.onMajEmettreSon)
    },

    methods: {
      init() {
        if (this.hasInit) {
          return
        }

        if (!this.chaine || this.chaine.indexOf('undefined') >= 0) {
          return
        }

        this.hasInit = true
        this.typeNouveauMessage = this.modeCommentaire ? 'commentaire' : ''

        this.initDonnees()
        this.initWs()
      },

      onMajEmettreSon(s) {
        console.log('onMajEmettreSon', s)
        this.emettreSon = s.info.valeur
      },

      envoyerMessage(event) {
        if (!this.nouveauMessage.trim()) {
          return
        }

        const contenu = this.nouveauMessage.trim()
        console.log(this.chaine)
        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/messages`,
          method: 'POST',
          params: {
            usager: this.strUsager,
            type: this.typeNouveauMessage,
            formateur: this.formateur,
          },
          data: contenu,
        })

        this.nouveauMessage = ''
        this.typeNouveauMessage = this.modeCommentaire ? 'commentaire' : ''
      },

      selectionnerTypeMessage(type) {
        this.typeNouveauMessage = type

        setTimeout(() => {
        // TODO: Remplacer par la version Vue adéquate
        // $('#txtSaisie').focus()
        })
      },

      annulerSaisie() {
        this.typeNouveauMessage = ''
        this.nouveauMessage = ''
      },

      like(idMessage) {
        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/like`,
          method: 'POST',
          params: {
            usager: this.strUsager,
          },
          data: idMessage,
        })
      },

      supprimer(idMessage) {
        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/messages`,
          method: 'DELETE',
          params: {
            usager: this.strUsager,
          },
          data: idMessage,
        })
      },

      supprimerTousLesMessage() {
        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/supprimerTousLesMessages`,
          method: 'DELETE',
          params: {},
          data: {},
        })
      },

      toggleEmettreSon() {
        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/emettreSon`,
          method: 'POST',
          params: {
            usager: this.strUsager,
            // On inverse la valeur avant de l'affecter sur le backend
            emettreSon: this.emettreSon,
          },
        })
      },

      initDonnees() {
        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/messages`,
          method: 'GET',
          params: {
            usager: this.strUsager,
          },
        }).then(reponseMessages => {
          reponseMessages.data.listeMessage.forEach(m => {
            this.messages.push(m)
          })
          reponseMessages.data.listeAuteur.forEach(a => {
            this.auteurs[a.id] = a
          })

          this.monId = reponseMessages.data.monId
          this.emettreSon = reponseMessages.data.emettreSon
          this.scrollToBottom(true)
          if (this.chaine.toLowerCase().startsWith('orka-k') && this.emettreSon === false) {
            this.emettreSon = true
            this.toggleEmettreSon()
          }
        })
      },

      initWs() {
        if (this.ws) {
          if (this.ws.readyState < 2) {
            return
          }
        }

        const baseWs = baseServeur.replace('http', 'ws')
        const urlWs = `${baseWs}/ws/${this.instance}/${this.chaine}`

        this.ws = new WebSocket(urlWs)

        this.ws.onclose = (action) => {
          if (action.code !== 4001) {
            this.initWs()
            this.ws.send('ping')
          }
        }

        this.ws.onmessage = event => {
          if (!isNaN(event.data)) {
            // console.log(event);
            return
          }

          const liste = JSON.parse(event.data)
          // console.log("Fil discussion", event, liste, liste.length);
          let nouveauMessageEntre = false
          let doitEmettreSon = false
          liste.forEach(i => {
            i = i.infos

            const message = i.message
            const auteur = i.auteur
            const type = i.type

            if (i.type === 'notification') {
              return
            }

            if (auteur && !this.auteurs[auteur.id]) {
              this.auteurs[auteur.id] = auteur
            }

            if (type === 'message') {
              if (
                this.messages.filter(function(m) {
                  return m.id === message.id
                }).length === 0
              ) {
                this.messages.push(message)
                nouveauMessageEntre = true

                if (message.auteur !== this.monId) {
                  doitEmettreSon = true
                }
              }
            } else if (type === 'like') {
              this.messages.forEach(function(m) {
                if (m.id === message.id) {
                  m.listeLikes = message.listeLikes
                }
              })
            } else if (type === 'supprimer') {
              let i = null
              this.messages.forEach(function(m, index) {
                if (m.id === message.id) {
                  i = index
                }
              })

              if (i !== null && i >= 0) {
                this.messages.splice(i, 1)
              }
            }
          })

          if (nouveauMessageEntre) {
            this.scrollToBottom(false)
          }

          console.log(doitEmettreSon, this.emettreSon, parseInt((new Date() - this.dateDerniereNotification)))
          if (doitEmettreSon && this.emettreSon && parseInt((new Date() - this.dateDerniereNotification)) > 2000) {
            notificationSonore.jouer()
            this.dateDerniereNotification = new Date()
          }
        }
      },

      // Recuperer reponses pour un message donne
      reponsesPour: function(msg) {
        return this.messages.filter(this.filtreMsgRep(msg))
      },

      filtreMsgRep: function(pmsg) {
        return function(cmsg) {
          return pmsg.id === cmsg.reponseA
        }
      },

      // Filtre utilise pour la liste de messages 'racines'
      // On retire ceux qui sont des réponses a un autre
      filtreMsgRoot(msg) {
        return !msg.reponseA
      },

      repondre: function(msg) {
        this.$set(this.nouvellesReponses, msg.id, '')
      },

      envoyerReponse: function(msgOriginal) {
        const contenu = this.nouvellesReponses[msgOriginal.id]
        if (!contenu || !contenu.trim()) {
          return
        }

        const type = 'commentaire'

        axios({
          url: `${baseServeur}/filDiscussion/${this.instance}/${this.chaine}/reponses`,
          method: 'POST',
          params: {
            usager: this.strUsager,
            type: type,
            formateur: this.formateur,
            message: msgOriginal.id,
          },
          data: contenu,
        })

        Vue.delete(this.nouvellesReponses, msgOriginal.id)
      },

      nePasRepondre: function(msg) {
        Vue.delete(this.nouvellesReponses, msg.id)
      },

      isAfficherRepondre: function(msg) {
        return typeof this.nouvellesReponses[msg.id] !== 'undefined'
      },

      scrollToBottom(ignorerScrollCourant) {
        setTimeout(() => {
          // TODO: remplacer par la version Vue
          // const divMessages = $('.messages')
          // if (
          //   ignorerScrollCourant ||
          //   divMessages.scrollTop() > divMessages.prop('scrollHeight') - 600
          // ) {
          //   divMessages.scrollTop(divMessages.prop('scrollHeight'))
          // }

          const scrollTop = this.$refs.messages.scrollTop
          const scrollHeight = this.$refs.messages.scrollHeight
          const breakpoint = scrollHeight - 600
          if (ignorerScrollCourant || scrollTop > breakpoint) {
            this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
          }
        })
      },
    },
  }
</script>
