<template>
  <v-dialog
    v-model="dialogQuestion"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title
        class="police-toucan"
        style="font-size:3rm"
      >
        {{ resultatQuestion? $t('vision.results') : $t('vision.repondez_a_la_question') }}
      </v-card-title>
      <v-card-text>
        <div v-html="question.texte" />
        <v-radio-group
          v-model="selectionChoix"
          :disabled="resultatQuestion"
        >
          <v-radio
            v-for="choix in question.listeChoix"
            :key="choix.id"
            :label="choix.texte"
            :value="choix.id"
          >
            <template v-slot:label>
              <div
                class="texteQuestion"
                v-html="choix.texte"
              />
              <div v-if="resultatQuestion">
                <v-icon class="mr-3">
                  mdi-account-group
                </v-icon>
                <span v-html="infoReponses(choix)" />
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-show="!resultatQuestion"
          class="primary"
          text
          :disabled="questionRepondue"
          @click="onRépondreQuestion()"
        >
          Envoyer ma réponse
        </v-btn>
        <v-btn
          text
          @click="dialogQuestion = false"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style >
div.texteQuestion p{
    padding: 0;
    margin:0;
    min-width: 80px;
}
</style>
<script>
  import restApiService from '@/services/restApiService.js'
  import Salon from '@/services/salon'

  export default {
    props: {
      conference: Object,
    },

    data: () => ({
      dialogQuestion: false,
      question: {
        texte: '',
        listeChoix: [],
      },
      salon: null,
      selectionChoix: null,
      afficherResultatQuestion: false,
      resultatQuestion: null,
      questionRepondue: false,

    }),
    beforeDestroy() {
      this.salon.sortir()
    },
    mounted () {
      this.salon = Salon.initConference(this.conference.id)
      this.salon.entrer('VisionSondage')
      this.salon.ajouterNotificationAfficherQuestion(this.onAfficherQuestion)
      this.salon.ajouterNotificationAfficherQuestionResultat(this.onAfficherQuestionResultat)
      this.salon.ajouterNotificationMasquerQuestion(this.onMasquerQuestion)
    },
    methods: {
      quitterPleinEcran() {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen()
        }
      },
      onAfficherQuestion (data) {
        this.question = data.info.question
        this.questionRepondue = false
        this.afficherResultatQuestion = false
        this.resultatQuestion = null
        var peutVoter = true
        if (this.conference.ouvertAtous && data.info.afficheur.id !== this.$store.state.userInfo.id) {
          this.quitterPleinEcran()
          this.dialogQuestion = true
        } else {
          restApiService.get(`/api/conferences/${this.conference.id}/usagers/${this.$store.state.userInfo.id}/participation`).then((result) => {
            peutVoter = result.data.peutVoter
            if (peutVoter && data.info.afficheur.id !== this.$store.state.userInfo.id) {
              this.quitterPleinEcran()
              this.dialogQuestion = true
            }
          })
        }
      },
      onAfficherQuestionResultat (data) {
        this.quitterPleinEcran()
        this.afficherResultatQuestion = true
        this.resultatQuestion = data.info
        if (data.info.afficheur.id !== this.$store.state.userInfo.id) { this.dialogQuestion = true }
      },
      onMasquerQuestion () {
        this.dialogQuestion = false
        // this.question = null
      },
      onRépondreQuestion () {
        const infoReponse = {
          idChoix: this.selectionChoix,
          idConference: this.conference.id,
        }
        restApiService.post('/api/repondreQuestion', infoReponse).then((res) => {
          this.questionRepondue = true
          this.$dialog.notify.success('Réponse envoyée')
        })
      },
      infoReponses (choix) {
        if (this.resultatQuestion?.listeInfoChoix) {
          const nr = this.resultatQuestion.listeInfoChoix.find(s => s.idChoix === choix.id).nombreRéponses
          var total = this.resultatQuestion.listeInfoChoix.reduce((acc, cur) => {
            return acc + cur.nombreRéponses
          }, 0)
          var pourcentage = Math.round(nr / total * 100)
          // var pourcentage = ''
          if (nr === 0) {
            return 'Aucune réponse'
          }
          if (nr === 1) {
            return '1 réponse (' + pourcentage + '%)'
          }
          return nr + ' réponses (' + pourcentage + '%)'
        }
        return ''
      },
    },
  }
</script>
