<template>
  <v-container
    v-if="conference"
    fluid
  >
    <vision-outils :conference="conference" />
    <h1 class="font-weight-light mb-2 text-h5">
      {{ conference.nom }}
    </h1>
    <v-card v-if="conference.urlPresentation">
      <v-card-title>{{ $t('diffusion.en_direct') }}</v-card-title>
      <v-card-text style="max-width: 1680px">
        <cnx-video-player
          ref="videoPlayer"
          :url-presentation="urlPresentationClient"
        />
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>{{ $t('diffusion.aucune_vidéo').toUpperCase() }}</v-card-title>
      <v-card-text> {{ $t('diffusion.il_n\'y_a_pas_de_vidéo_configurée') }} </v-card-text>
    </v-card>
    <v-card v-if="conference.information">
      <v-card-title>{{ $t('conference.information') }}</v-card-title>
      <v-card-text>
        <div v-html="conference.information" />
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <v-btn
          class="primary mt-2 mt-2 col-12 col-sm-4 col-md-4 col-lg-3"
          @click="retourAccueil()"
        >
          {{ $t('diffusion.retour_à_la_salle_des_présentations') }}
        </v-btn>

        <v-btn
          v-if="mode === 'responsable'"
          class="mt-2 col-12 col-sm-4 col-md-4 col-lg-3"
          @click="onAccederEnregistrement"
        >
          {{ $t("diffusion.acceder_a_l_enregistrement") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <redirection-atelier
          :conference="conference"
          :mode-responsable="mode === 'responsable'"
        />
      </v-col>
    </v-row>

    <vision-sondage :conference="conference" />

    <chat
      :masquer-fil-discussion="conference.masquerFilDiscussion"
      :chaine="chaineConference"
      :formateur="mode === 'responsable'"
      :user-info="$store.state.userInfo"
      :titre="$t('diffusion.questions_liées_à_la_présentation')"
    >
      <template v-slot:btn-extra>
        <prise-parole
          :conference="conference"
          @redirection="$refs.videoPlayer.desactiverAudio()"
        />
      </template>
    </chat>

    <chat
      :chaine="chaineSupportTechnique"
      :formateur="mode === 'responsable'"
      :support-technique="true"
      :user-info="$store.state.userInfo"
      class="mt-3"
    />

    <gestion-question
      v-if="mode === 'responsable'"
      :chaine="chaineConference"
      :user-info="$store.state.userInfo"
    />

    <gestion-prise-parole
      v-if="mode === 'responsable' && conference.priseParole"
      :conference="conference"
    />
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import CnxVideoPlayer from '../composantes/CnxVideoPlayer'
  import Chat from '../../agora/Chat'
  import GestionQuestion from '../../agora/GestionQuestion'
  import VisionOutils from './VisionOutils'
  import VisionSondage from './VisionSondage'
  import RedirectionAtelier from './gestion/RedirectionAtelier.vue'
  import GestionPriseParole from '../composantes/GestionPriseParole.vue'
  import PriseParole from '../composantes/PriseParole.vue'
  import moment from 'moment'
  import i18n from '@/i18n.js'

  export default {
    components: {
      CnxVideoPlayer,
      Chat,
      GestionQuestion,
      VisionOutils,
      VisionSondage,
      RedirectionAtelier,
      GestionPriseParole,
      PriseParole,
    },

    data: () => ({
      conference: undefined,
      urlPresentationClient: undefined,
      mode: '',
      okButtonText: { true: 'Ok' },
    }),

    computed: {
      chaineConference () {
        const chaine = 'ORKA-C-' + this.conference.id
        return chaine
      },

      chaineSupportTechnique () {
        const chaine = `ORKA-C-${this.conference.id}-T`
        return chaine
      },
    },

    created () {
      restApiService
        .get('/api/webUser/infoConference/' + this.$route.params.idConference)
        .then(result => {
          this.conference = result.data.conference
          this.urlPresentationClient = result.data.urlPresentationClient
          if (result.data.estResponsable) {
            this.mode = 'responsable'
          } else {
            this.mode = 'participant'
          }
          this.onConférenceFini()
        })
        .catch(error => alert(error))
    },

    methods: {
      retourAccueil () {
        this.$router.push({
          path: `/orka/eve/${this.conference.evenement.id}/sallepresentations`,
        })
      },

      onAccederEnregistrement () {
        this.$router.push({ path: `/orka/presentation/${this.conference.id}` })
      },

      onConférenceFini() {
        var page = this.$route.fullPath
        var delaiMax = Math.pow(2, 31) - 1
        var tempsRestant = moment(this.conference.dateFermeture).diff(new Date()).valueOf()
        if (tempsRestant >= 0) {
          tempsRestant = tempsRestant > delaiMax ? delaiMax : tempsRestant
          setTimeout(async () => {
            if (this.$route.fullPath === page) {
              if (document.fullscreenElement) {
                document.exitFullscreen()
              }
              const dialog = await this.$dialog.warning(
                {
                  title: i18n.t('general.attention'),
                  text: i18n.t('diffusion.fin_conference_message'),
                  actions: this.okButtonText,
                  waitForResult: false,
                },
              )
              var okButtonText = 'Ok'
              var countdown = 10
              const countdownInterval = setInterval(() => {
                this.okButtonText.true = okButtonText + ` (${countdown})`
                countdown -= 1
                if (countdown <= -1) {
                  clearInterval(countdownInterval)
                  dialog.close()
                  this.retourAccueil()
                }
              }, 1000)
            }
          }, tempsRestant)
        } else {
          this.retourAccueil()
        }
      },
    },
  }
</script>
